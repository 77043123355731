import BaseBean from "@/utils/BaseBean";
import {ElForm} from 'element-plus';
import config from "@/utils/config";
export interface ILoginForm {
    usercode: string
    password: string
    verifyCode: string
}
export interface ILoginDataObj{
    refMap:Map<string,any>
    sliderStatus: boolean//滑块状态（滑块是否验证成功）
    yzmType:number//验证码种类。0：滑块验证 1：数字验证
    backgroundImage:string//登录页背景图片（onMounted里面会改变）
    useYzm: boolean//登录是否启用验证码
    loginForm: ILoginForm
    loginFormRules:TFormRule
    fullscreenLoading:boolean//点击登录按钮，为页面增加遮罩层，该变量控制的是遮罩层的显示/影藏状态
    loginMsg:string//登录页面欢迎标语
    otherParams:any
}
export default class LoginUtil extends BaseBean{
    public appInstant:any;
    public dataObj: ILoginDataObj;
    constructor(proxy:any,appInstant:any,dataObj:ILoginDataObj){
        super(proxy);
        this.appInstant=appInstant;
        this.dataObj=dataObj;
    }
    //进入登录页面，请求后台，获取图片资源，设置页面背景
    public async loginInit(dataObj:TCommonObj):Promise<void>{
        let res = await this.utils.Api.loginInitData({applications:config.applications});//获取进入登录页面的初始化数据
        if(res.result){
            this.dataObj.otherParams.needMultiLanguage=res.needMultiLanguage;
            sessionStorage.setItem("needMultiLanguage", res.needMultiLanguage);//设置是否需要多语言支持
            sessionStorage.setItem("encryptData", res.encryptData);//设置是否加密传输数据
            sessionStorage.setItem("canRegister", res.canRegister);//设置是否加密传输数据
            sessionStorage.setItem("animations", res.animations);//默认开启的动画
            dataObj.otherParams.canRegister=res.canRegister;
            dataObj.useYzm=res.useYzm;//是否需要验证码
            dataObj.yzmType=res.yzmType;
            dataObj.loginMsg=res.loginMsg;
            if(res.imageSource && res.imageSource.length!=0){//如果图片资源不为空
                let picIndex=Math.floor(Math.random()*res.imageSource.length);//随机生成背景图片下标
                dataObj.backgroundImage='url('+res.imageSource[picIndex].path+')';
                sessionStorage.setItem("imageSource", this.utils.UtilPub.encrypt(JSON.stringify(res.imageSource)));//把图片资源放到sessionStorage中
            }else{
                // const staticImgPromise = import('@/assets/login/'+Math.floor(Math.random() * 5)+'.png');
                const staticImgPromise = import('@/assets/login/0.jpg');
                staticImgPromise.then((module) =>dataObj.backgroundImage='url('+module.default+')');
            }
        }else{
            this.proxy.$message.error(this.proxy.$t('login.loginInitWrong'))
        }
    }
    //系统建议用谷歌浏览器，这里检查是否为谷歌浏览器，给予相应提示
    public checkBrows():void{
        let userAgent=navigator.userAgent;
        if(userAgent.indexOf("Chrome")==-1){
            this.proxy.$notify({
                title: this.proxy.$t('tools.tip'),
                type: 'warning',
                dangerouslyUseHTMLString: true,
                position: 'top-left',
                message: this.proxy.$t('login.browerMsg'),
                duration: 0
            });
        }
    }
    //页面控件回车处理事件（跳转到下一个操作项，相当于tab）
    public async doNext(field:string):Promise<void>{
        switch (field) {
            case "usercode"://如果当前是usercode发出的回车事件，则把光标定位到password输入框
                this.dataObj.refMap.get('passwordRef').focus();
                break;
            case "password"://如果当前是password发出的回车事件，则看是否需要验证码，需要验证码且为数字验证码，那么光标定位到数字输入框，否则提交登录表单
                if (this.dataObj.useYzm && this.dataObj.yzmType==1) {
                    this.dataObj.refMap.get('verifyCodeRef').focus();
                } else {
                    this.submitForm();
                }
                break;
            case "verifyCode"://如果当前是verifyCode发出的回车事件，则提交登录表单
                this.submitForm();
                break;
        }
    }
    //登录表单提交事件
    public submitForm():void{
        this.dataObj.refMap.get('loginFormRef').validate(async (valid:BufferSource) => {
            if (valid) {
                await this.doLogin();
            } else {
                this.proxy.$message.info(this.proxy.$t('login.completeMsg'));
            }
        });
    }
    //请求后台，验证用户合法与否
    public async doLogin():Promise<void>{
        this.dataObj.otherParams.isLoading=true;
        //-----------------------------------------1、前端验证码验证
        if(this.dataObj.useYzm && this.dataObj.yzmType==1){
            let isVerifyCodeRight=this.dataObj.refMap.get('verifyCodeComRef').verfiCode(this.dataObj.loginForm.verifyCode);
            if(!isVerifyCodeRight){
                this.utils.Tools.info({message: this.proxy.$t('login.verifyCodeWrong')});
                this.dataObj.otherParams.isLoading=false;
                return ;
            }
        }else if(this.dataObj.useYzm && this.dataObj.yzmType==0 && !this.dataObj.sliderStatus){//滑块验证没有通过
            this.utils.Tools.info({message: this.proxy.$t('login.moveSliderNote')});
            this.dataObj.otherParams.isLoading=false;
            return;
        }
        this.dataObj.fullscreenLoading=true;
        //-----------------------------------------2、带着form表单去请求后台验证
        this.dataObj.loginForm.password = this.utils.UtilPub.encrypt(this.dataObj.loginForm.password);//把输入密码加密处理
        let res = await this.utils.Api.login(this.dataObj.loginForm);
        if(res[this.utils.Const.code]==this.utils.Const._0000){//----------------------------3、验证成功
            // location.href="https://www.baidu.com/"
            //注意：放入vuex中的东西在刷新的时候会丢掉，所以在路由全局拦截那里需要再次设置；
            //     放到sessionStorage里面的东西刷新的时候不会丢掉，关闭浏览器之后会丢掉
            //     放入localStorage里面的东西，不管刷新还是关闭浏览器都不会丢掉
            this.store.commit("setNeedCorp", res.needCorp);//设置是否需要机构到vuex
            this.store.commit("setNeedDept", res.needDept);//设置是否需要部门到vuex
            this.store.commit("setNeedPerson", res.needPerson);//设置是否需要人员到vuex
            this.store.commit("setUseDataLevel", res.useDataLevel);//设置是否启用数据权限控制到vuex
            this.store.commit("setUploadSaveType", res.uploadSaveType);//设置文件上传处理方式到vuex
            this.store.commit("setMsgReceivePermission", res.hasMessageReceivePermission);//设置是否具有接收消息的权限数据到vuex
            this.store.commit("setChangePersonalInfoPermission", res.hasChangePersonalInfosPermission);//设置是否具有修改个人信息的权限数据到vuex

            sessionStorage.setItem("imageSource", this.utils.UtilPub.encrypt(JSON.stringify(res.imageSource)));//把图片资源放到sessionStorage中
            sessionStorage.setItem("userMenus", this.utils.UtilPub.encrypt(res.userMenus)); //设置菜单字符串，方便在slidBar那里取该用户的菜单
            sessionStorage.setItem("showLayer", res.showLayer);//设置是否显示layer
            if(res.showLayer){
                sessionStorage.setItem("layerShowType", res.layerShowType);//设置layer显示方式
                if(res.userLayerData)sessionStorage.setItem("userLayerData", this.utils.UtilPub.encrypt(res.userLayerData)); //设置用户layer布局字符串
            }
            sessionStorage.setItem("welcomeMsg", res.welcomeMsg);//设置welcomeMsg

            this.dataObj.loginForm.password = '';//清空密码输入框
            //res.hasEditPortal,是否具有门户,res.userName为帐号的昵称，把它赋值给loginForm.userName，最后会显示在top组件的个人信息那里
            let userInfo:IStoreUserInfo=Object.assign({},this.dataObj.loginForm,{userId:res.userId,hasEditPortal:res.hasEditPortal,userName:res.userName});
            sessionStorage.setItem("userInfo",this.utils.UtilPub.encrypt(JSON.stringify(userInfo)));//存储userInfo
            sessionStorage.setItem(this.utils.Const.jfAccessToken, res[this.utils.Const.jfAccessToken]);//存储token
            localStorage.setItem(this.utils.Const.jmReportAccessToken, res[this.utils.Const.jfAccessToken]);//存储积木报表的token（可删）

            sessionStorage.setItem('billPages',this.utils.UtilPub.encrypt(res.billPages));//所有单据的地址（{billType1:'/dir1/dir2/aPage',billType2:'/dirX/dirY/bPage'}）
            sessionStorage.setItem('applications',this.utils.UtilPub.encrypt(res.applications));//系统模块集合字符串
            sessionStorage.setItem('mainMsg',res.mainMsg);//主页动画的欢迎语

            //如果后台服务重启了，或者点击退出登录到达登录页面都需要重新创建websocket，此时全局$socket还在，只是状态变为close了。
            //所以我们加了if-else判断，只要全局$socket对象还在，但是状态不为1（正常），那么重新创建一个WebSocket对象赋值给$socket。
            //如果全局$socket对象都不存在了，那么需要彻底的创建出一个websocket对象赋值给全局$socket（刷新就会走这种情况创建）
            //创建好之后把它放到vue原型链上。
            if(this.proxy.$socket && this.proxy.$socket.readyState!=1){
                let socketUrl=this.proxy.$socket.url.substr(0,this.proxy.$socket.url.lastIndexOf('/'));
                socketUrl=socketUrl+"/"+this.dataObj.loginForm.usercode;
                let $socket=new WebSocket(socketUrl);
                this.proxy.$socket = $socket;
            }else{
                await this.utils.Websocket.createWebSocketCon(this.dataObj.loginForm.usercode,this.appInstant);
            }
            await this.utils.Websocket.initWebSocket(this.appInstant);

            this.dataObj.fullscreenLoading=false;//关闭遮罩层，方便看到动画和登录成功的提示信息
            //清除锁屏信息
            if(sessionStorage.getItem("lockScreen")){
                let lockScreen:ILockScreen={lockScreen:false,lockScreenState:'unLocked'};
                sessionStorage.setItem("lockScreen",JSON.stringify(lockScreen));
            }
            //如果有设置标题
            if(res.systemTitle) sessionStorage.setItem("systemTitle",res.systemTitle);
            if(this.utils.Tools.isAnimationOpen('loginAnimation')){//如果需要登录成功动画
                this.loginActAnimation();
                setTimeout(()=> {
                    this.toPage(res);
                }, 1000);//不知道为什么，动画执行了3秒，这里如果设置3秒，会等很久
            }else{
                this.toPage(res);
            }
        }else{//----------------------------4、验证失败
            this.dataObj.loginForm.password = '';//清除密码输入框
            this.utils.Tools.info({message: res.msg});
            if(this.dataObj.useYzm && this.dataObj.yzmType==1){//如果要用验证码而且采用的是数字验证码，那么需要刷新数字
                this.dataObj.refMap.get('verifyCodeComRef').createCode();
                this.dataObj.loginForm.verifyCode = '';
            }else if(this.dataObj.useYzm && this.dataObj.yzmType==0){//如果要用验证码而且采用的是滑块验证码，那么需要重置滑块
                this.dataObj.sliderStatus=false;
                this.dataObj.refMap.get('sliderRef').resetSlider();
            }
            this.dataObj.fullscreenLoading=false;//关闭页面遮罩层
            this.dataObj.otherParams.isLoading=false;
        }
    }
    //登录成功之后的login动画（把登录表单飞到右上角）
    public loginActAnimation():void{
        let width=window.innerWidth;
        let height=window.innerHeight;
        let dom:any=document.getElementById("loginForm");
        dom.style="transform: translate("+width+"px,-"+height+"px) scale(0) rotate(45deg);transition: all 3s;";
    }
    //登录成功之后跳转
    public toPage(res:any):void{
        if(res.showLayer){
            this.proxy.$router.push('/layer');
        }else{
            this.proxy.$router.push('/');
        }
        this.utils.Tools.success({message: this.proxy.$t('login.loginSuccessMsg')});
    }
}